import { useEffect } from 'react';

export const useInterval = (callback, dependencies, interval) => (
  useEffect(() => {
    const intervalId = setInterval(callback, interval);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [...dependencies, interval])
);
