import { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { refreshChannels, selectChannels } from 'slices/channels';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  AddAPhotoOutlined as AddAPhotoOutlinedIcon,
  ArrowBack as ArrowBackIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon,
  PersonAdd as PersonAddIcon,
  Refresh as RefreshIcon,
  SentimentSatisfiedOutlined as SentimentSatisfiedOutlinedIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { Centered, Chat } from 'components';
import { useAPI, useBackAction, useInterval, useTitle } from 'hooks';
import './chats-search.css';

const ChannelsSearch = () => {
  const chatsFilterRef = useRef();
  const dispatch = useDispatch();
  const { ChannelsAPI } = useAPI();
  const chats = useSelector(selectChannels);
  const items = [];
  const [filter, setFilter] = useState('');
  const filteredItems = chats?.filter((c) => c.name?.toLowerCase().includes(filter.toLowerCase()));

  useEffect(() => {
    dispatch(refreshChannels(ChannelsAPI));
  }, []);

  useLayoutEffect(() => {
    chatsFilterRef.current?.focus();
  }, [chatsFilterRef.current]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            backgroundImage: 'none',
            backgroundColor: 'var(--tg-theme-bg-color)',
            boxShadow: '0px 0px 1px 0px rgb(0 0 0)',
            color: 'var(--tg-theme-button-text-color)',
          }}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => window.history.back()}
              sx={{ mr: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <TextField
              autoFocus={true}
              ref={chatsFilterRef}
              placeholder="Поиск"
              sx={{ '& fieldset': { border: 'none' } }}
              fullWidth
              onChange={({ target: { value } }) => setFilter(value)}
            />
          </Toolbar>
        </AppBar>
      </Box>

      <div className="chats">
        {filteredItems?.map(chat => (
          <Chat key={chat.id} {...chat} />
        ))}
        {!filteredItems?.length && (
          <Centered>
            {filteredItems ? 'Ничего не найдено' : 'Загрузка...'}
          </Centered>
        )}
      </div>
    </>
  );
};

export default ChannelsSearch;
