import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const toTimeHHMM = (timestamp) => {
  const date = new Date(timestamp);

  return `${[0, date.getHours()].join('').slice(-2)}:${[0, date.getMinutes()].join('').slice(-2)}`
};

const PublicationTime = ({ timestamp }) => {
  if (!timestamp) {
    return (
      <Skeleton
        variant="text"
        sx={{
          bgcolor: 'rgba(128, 128, 128, 0.2)',
          display: 'inline-block',
          width: 30,
        }}
      />
    );
  }

  return toTimeHHMM(timestamp);
};

export default PublicationTime;
