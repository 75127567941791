export async function create({ id, invite_to_chat_id }) {
  const response = await this.post('/connections', {
    connection: { id, invite_to_chat_id },
  });

  return response;
};

export async function join({ id }) {
  const response = await this.patch(`/connections/${id}`);

  return response;
};

export async function destroy({ id }) {
  const response = await this.delete(`/connections/${id}`);

  return response;
};
