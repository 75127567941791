import React from 'react';
import { Centered, Account } from 'components';
import { useSelector } from 'react-redux';
import { selectEncryptionKey } from 'slices/auth';
import './accounts.css';

const Accounts = ({ items }) => {
  const encryptionKey = useSelector(selectEncryptionKey);

  if (!items) return (
    <Centered>
      Загрузка...
    </Centered>
  );

  return (
    <div className="accounts">
      {items.map(account => (
        <Account {...account} />
      ))}
      {!items.length && (
        <Centered>
          Здесь нет сохраненных аккаунтов
        </Centered>
      )}
    </div>
  );
};

export default Accounts;
