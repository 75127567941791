import React, { useState } from 'react';
import './message.css';

import { useAPI, useIntersectionObserver } from 'hooks';

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PinIcon from '@mui/icons-material/PushPin';
import ReplyIcon from '@mui/icons-material/Reply';

import { InstagramPost, PublicationTime, TelegramPost, YoutubePost } from 'components';

const Message = ({
  chatId, created_at, hidden_at, id, onObserve, onUnobserve, text, updated_at,
  previousFromSameMember, nextFromSameMember, membership: { id: memberId, name, photo_url },
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHidden, setHidden] = useState(false);
  const open = Boolean(anchorEl);

  const { MessagesAPI } = useAPI();

  // console.log('Message component rerender')

  const [isVisible, containerRef] = useIntersectionObserver({
    root: document.querySelector('.messages'),
    onObserve: () => onObserve({ created_at, id }),
    onUnobserve: () => onUnobserve({ created_at, id }),
  })

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleContextMenu = () => {

  };

  const handleEdit = () => {

  };

  const handleDelete = () => {
    setHidden(true);
    MessagesAPI.destroy({ chatId, id });
  };

  const isInstagramPost = text?.match && text.match(/^https:\/\/www\.instagram\.com\/p\/\S+$/);
  const isTelegramPost = text?.match && text.match(/^https:\/\/t\.me\/\w+\/\d+$/);
  const isYoutubePost = text?.match && (
    text.match(/^https:\/\/www\.youtube\.com\/watch\S*[\?\&]v=\S+$/)
    || text.match(/^https:\/\/youtu\.be\/\S+$/)
    || text.match(/^https:\/\/www\.youtube\.com\/shorts\/\S+$/)
  );

  if (isHidden || hidden_at) {
    return (
      <div className={`message message--hidden ${nextFromSameMember ? 'message--has-following' : ''}`} id={`message-${id}`} ref={containerRef}>
        <div className="message__avatar">
          {!nextFromSameMember && (
            <Avatar src={photo_url} />
          )}
        </div>
        <div className="message__content">
          {/*chat.type !== 'personal'*/ !previousFromSameMember && (
            <div className="message__sender">
              {name}
            </div>
          )}
          <div className="message__text">
            Сообщение удалено
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`message ${nextFromSameMember ? 'message--has-following' : ''}`} id={`message-${id}`} ref={containerRef}>
      <div className="message__avatar">
        {!nextFromSameMember && (
          <Avatar src={photo_url} />
        )}
      </div>
      <div
        className="message__content"
        onClick={handleClick}
        onContextMenu={handleContextMenu}
      >
        {/*chat.type !== 'personal'*/ !previousFromSameMember && (
          <>
            <div className="message__sender">
              {name}
            </div>
            {(isInstagramPost || isTelegramPost || isYoutubePost) && <div style={{ height: '15px' }}/>}
          </>
        )}
        <div className="message__text">
          {isInstagramPost && (
            <InstagramPost url={text} />
          ) || isTelegramPost && (
            <TelegramPost src={text} />
          ) || isYoutubePost && (
            <YoutubePost url={text} />
          ) || (text)}
          <span style={{ display: 'inline-block', width: '50px' }}></span>
        </div>
        <div className="message__footer">
          <div className="message__time">
            <PublicationTime timestamp={created_at} />
          </div>
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem disabled onClick={() => console.log('pinMessage', id)}>
          <ListItemIcon>
            <PinIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Закрепить</ListItemText>
        </MenuItem>
        <MenuItem disabled onClick={() => console.log('setReplyMessageId', id)}>
          <ListItemIcon>
            <ReplyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Ответить</ListItemText>
        </MenuItem>
        <MenuItem disabled onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Редактировать</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Удалить</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Удалить сообщение
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Вы точно хотите удалить сообщение?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsDeleteDialogOpen(false)}
          >
            Отмена
          </Button>
          <Button
            autoFocus
            color="error"
            onClick={handleDelete}
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Message;
