import React, { useEffect, useState, useCallback } from 'react';
import { Button } from '@mui/material';

const lockPullToRefresh = (value) => {
  document.body.style.overflow = value ? 'hidden' : null;
  document.body.parentNode.style.overflow = value ? 'hidden' : null;
  document.body.style.overscrollBehavior = value ? 'none' : null;
  document.body.parentNode.style.overscrollBehavior = value ? 'none' : null;
  document.body.style.touchAction = value ? 'none' : null;
  document.body.parentNode.style.touchAction = value ? 'none' : null;
};

const createBlankGrid = (size) => Array(size).fill().map(() => Array(size).fill([0]));

const toggleGridCell = (prevGrid, rowIndex, colIndex, inverted) => {
  const maxItem = Math.max(...prevGrid.flat().flat());
  const newGrid = prevGrid.map(row => [...row]);
  const oldValue = newGrid[rowIndex][colIndex].filter(Boolean);
  if (inverted) {
    newGrid[rowIndex][colIndex] = [0];
  } else if (oldValue.includes(maxItem)) {
    return newGrid;
  } else {
    newGrid[rowIndex][colIndex] = [...oldValue, maxItem + 1];
  }
  return newGrid;
};

const fillGridRandomly = async (grid, toggleCell) => {
  const directions = [
    [-1, 0],
    [1, 0],
    [0, -1],
    [0, 1],
  ];

  const isValidCell = (x, y) => x >= 0 && y >= 0 && x < grid.length && y < grid[0].length;

  const canVisitCell = (x, y) =>
    isValidCell(x, y) && grid[x][y].length < 3;

  const getRandomDirection = (x, y) => {
    const possibleDirections = directions.filter(([dx, dy]) =>
      canVisitCell(x + dx, y + dy)
    );

    if (possibleDirections.length === 0) return null;
    return possibleDirections[Math.floor(Math.random() * possibleDirections.length)];
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fillStep = async () => {
    let x, y;

    do {
      x = Math.floor(Math.random() * grid.length);
      y = Math.floor(Math.random() * grid[0].length);
    } while (!isValidCell(x, y) || grid[x][y][0] !== 0);

    toggleCell(x, y);
    grid = toggleGridCell(grid, x, y);

    let currentValue = 1;

    while (currentValue < 15 || (grid.flat().every((c) => c.length <= 2))) {
      const direction = getRandomDirection(x, y);
      if (!direction) break;

      const [dx, dy] = direction;
      x += dx;
      y += dy;

      if (!canVisitCell(x, y)) break;

      await delay(400);
      toggleCell(x, y);
      grid = toggleGridCell(grid, x, y);

      currentValue++;
    }
  };

  await fillStep();
};

export const GridDraw = ({ onComplete }) => {
  const [isExample, setIsExample] = useState(false);
  const [pointer, setPointer] = useState([4.5, 4.5]);
  const size = 10;
  const minLength = 5;
  const minScore = 15;
  const [grid, setGrid] = useState(createBlankGrid(size));
  const [isInverted, setIsInverted] = useState(false);
  const [isInteracting, setIsInteracting] = useState(false);
  const currentLength = grid.flat().filter((a) => a[0] != 0).length;
  const currentScore = Math.max(...grid.flat().flat());
  const isNonLine = () => {
    const verticalItems = [];
    const horizontalItems = [];
    grid.forEach((row, i) => {
      row.forEach((item, j) => {
        if (item[0] != 0) {
          if (!verticalItems.includes(i)) verticalItems.push(i);
          if (!horizontalItems.includes(j)) horizontalItems.push(j);
        }
      });
    });

    return verticalItems.length > 1 && horizontalItems.length > 1;
  };
  const isValid =
    isNonLine() &&
    currentLength >= minLength &&
    currentLength <= size * size - minLength &&
    grid.flat().some((c) => c.length > 2) &&
    currentScore >= minScore;

  const getErrorText = () => {
    const minLengthLeft = minLength - currentLength;
    if (!currentLength) return 'Показать пример как рисовать?';
    if (currentLength < minLength) return `Закрасьте хотя бы еще ${minLengthLeft} ${minLengthLeft > 1 ? 'клетки' : 'клетку'}`;
    if (currentLength > size * size - minLength) return `Оставьте хотя бы ${minLength} клеток`;
    if (!isNonLine()) return 'Прямые линии это слишком просто :)';
    if (grid.flat().every((c) => c.length <= 1)) return 'Покружите через одни и те же клетки';
    if (grid.flat().every((c) => c.length <= 2)) return 'Продолжайте кружить для надежности :)';
    if (currentScore < minScore) return `Дойдите хотя бы до ${minScore} (еще ${minScore - currentScore})`;
  };

  useEffect(() => {
    lockPullToRefresh(isInteracting);

    return () => lockPullToRefresh(false);
  }, [isInteracting]);

  const handleContinue = () => {
    if (!currentLength) {
      setIsExample(true);
      fillGridRandomly(grid, toggleCell);
      return;
    }
    if (isExample) {
      setIsExample(false);
      setGrid(createBlankGrid(size));
      return;
    }
    onComplete(grid.flat().join(''));
  }

  const toggleCell = useCallback((rowIndex, colIndex, inverted) => {
    setPointer([colIndex, rowIndex]);
    setGrid(prevGrid => toggleGridCell(prevGrid, rowIndex, colIndex, inverted));
  }, []);

  const handleStart = useCallback((rowIndex, colIndex) => {
    if (isExample) return;
    setIsInteracting(true);
    lockPullToRefresh(true);
    const inverted = !!grid[rowIndex][colIndex][0];
    setIsInverted(inverted);
    toggleCell(rowIndex, colIndex, inverted);
  }, [grid, toggleCell, isExample]);

  const handleMove = useCallback((rowIndex, colIndex) => {
    if (isExample) return;
    lockPullToRefresh(true);
    if (isInteracting) {
      toggleCell(rowIndex, colIndex, isInverted);
    }
  }, [isInteracting, toggleCell, isInverted, isExample]);

  const handleEnd = (event) => {
    event.preventDefault();
    if (isExample) return;
    setIsInteracting(false);
  };

  const getCellPosition = (event, element) => {
    const rect = element.getBoundingClientRect();
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;

    const x = clientX - rect.left;
    const y = clientY - rect.top;

    const colIndex = Math.floor((x / rect.width) * size);
    const rowIndex = Math.floor((y / rect.height) * size);

    return { rowIndex, colIndex };
  };

  const handleTouchMove = useCallback((event) => {
    event.preventDefault();
    if (isExample) return;
    lockPullToRefresh(true);
    const element = event.currentTarget;
    const { rowIndex, colIndex } = getCellPosition(event, element);

    if (rowIndex >= 0 && rowIndex < size && colIndex >= 0 && colIndex < size) {
      handleMove(rowIndex, colIndex);
    }
  }, [handleMove]);

  const handleTouchStart = useCallback((event) => {
    event.preventDefault();
    if (isExample) return;
    lockPullToRefresh(true);
    const element = event.currentTarget;
    const { rowIndex, colIndex } = getCellPosition(event, element);

    if (rowIndex >= 0 && rowIndex < size && colIndex >= 0 && colIndex < size) {
      handleStart(rowIndex, colIndex);
    }
  }, [handleStart]);

  return (
    <div style={{
      width: 'calc(100% - 40px)',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div
        style={{
          aspectRatio: '1 / 1',
          cursor: 'crosshair',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          maxHeight: 'calc(100vh - 300px)',
        }}
        onMouseUp={handleEnd}
        onMouseLeave={handleEnd}
        onTouchEnd={handleEnd}
        onTouchCancel={handleEnd}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
      >
        {grid.map((row, rowIndex) => (
          <div key={rowIndex} style={{ display: 'flex', flexGrow: 1, }}>
            {row.map((cell, colIndex) => (
              <div
                key={`${rowIndex}-${colIndex}`}
                onMouseDown={(e) => {
                  e.preventDefault();
                  handleStart(rowIndex, colIndex);
                }}
                onMouseEnter={(e) => {
                  e.preventDefault();
                  handleMove(rowIndex, colIndex);
                }}
                style={{
                  alignItems: 'center',
                  backgroundColor: cell[0] ? `hsl(50 ${Math.ceil(70 - 70 / cell.length)} ${Math.ceil(50 / cell.length)})` : 'var(--tg-theme-bg-color)',
                  border: '1px solid var(--tg-theme-secondary-bg-color)',
                  display: 'flex',
                  flexGrow: 1,
                  justifyContent: 'center',
                }}
              >
                <div style={{
                  alignItems: 'center',
                  color: cell[0] ? 'var(--tg-theme-text-color)' : 'rgba(255,255,255,0.15)',
                  display: 'flex',
                  fontSize: '12px',
                  justifyContent: 'center',
                  height: 0,
                  width: 0,
                }}>
                  {cell[0] ? cell.join(',') : `${'ABCDEFGHIJ'[rowIndex]}${colIndex + 1}`}
                </div>
              </div>
            ))}
          </div>
        ))}
        {[1, 3, 5, 7, 9].map((x) =>
          [1, 3, 5, 7, 9].map((y) => (
            <div
              key={`dot-${x}-${y}`}
              style={{
                background: `hsl(${Math.ceil(360 / size * y)} ${Math.ceil(100 / size * x)} ${Math.ceil(30 + 20 / size * x)})`,
                width: '3vmin',
                height: '3vmin',
                borderRadius: '3vmin',
                position: 'absolute',
                top: `${y * 100 / size}%`,
                left: `${x * 100 / size}%`,
                transform: 'translate(-50%, -50%)',
              }}
            />
          ))
        )}
        {isExample && (
          <div
            key="dot-pointer"
            style={{
              background: 'rgba(255, 255, 255, 0.5)',
              width: '8vmin',
              height: '8vmin',
              borderRadius: '8vmin',
              position: 'absolute',
              transition: 'all 200ms',
              top: `calc(${pointer[1] * 100 / size}% + 4.6vmin)`,
              left: `calc(${pointer[0] * 100 / size}% + 4.6vmin)`,
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
      </div>
      <Button
        disabled={!isValid && currentLength}
        variant="text"
        component="label"
        onClick={handleContinue}
        sx={{
          backgroundColor: 'var(--tg-theme-bg-color) !important',
          color: 'var(--tg-theme-text-color)',
          display: 'flex',
          flexDirection: 'column',
          height: '60px',
          marginTop: '20px',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <div style={{
            color: 'var(--tg-theme-hint-color)',
            fontSize: '12px',
            fontWeight: '400',
            padding: '20px 15px',
          }}>
          {isValid ? (isExample ? 'Вернуться к рисованию' : 'Продолжить') : getErrorText()}
        </div>
      </Button>
    </div>
  );
};

export default GridDraw;
