import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hardSignout, signout } from 'slices/auth';
import { getRandomUuid } from 'utils/uuid';
import { isSameObject } from 'utils/comparators';

const initialState = {
  collection: null,
  status: null,
};

export const refreshChannels = createAsyncThunk(
  'channels/pull',
  async (channelsAPI) => {
    const data = await channelsAPI.all();

    return data;
  }
);

export const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    updateMembership: (state, action) => {
      const { collection: channels } = state;
      const [ChannelMembershipsAPI, { channelId, ...params }] = action.payload;

      const channel = channels.find((c) => c.id === channelId);
      const membership = { ...channel.membership, ...params };

      if (isSameObject(channel.membership, membership)) return;

      ChannelMembershipsAPI.update(channelId, membership);
      channel.membership = membership;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signout.pending, (state) => {
        state.collection = null;
        state.status = null;
      })
      .addCase(hardSignout.pending, (state) => {
        state.collection = null;
        state.status = null;
      })
      .addCase(refreshChannels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(refreshChannels.fulfilled, (state, action) => {
        state.collection = action.payload;
      });
  },
});

export const {
  updateMembership,
} = channelsSlice.actions;

export const selectChannels = (state) => state.channels.collection;

export default channelsSlice.reducer;
