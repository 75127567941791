import React from 'react';
import './centered.css';

const Centered = ({ children, height = '100%', width = '100%' }) => (
  <div className="centered" style={{ height, width }}>
    <span className="centered__content">{children}</span>
  </div>
)

export default Centered;
