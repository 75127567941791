import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useBackAction = (callback) => {
  const navigate = useNavigate();

  const wrappedCallback = (event) => {
    event.preventDefault();
    callback({ navigate });
  };

  useEffect(() => {
    window.history.pushState('fake-route', document.title, window.location.href);

    window.onpopstate = wrappedCallback
  }, [wrappedCallback]);
};
