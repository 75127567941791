import React, { Component } from 'react'

const defaultStyle = {
  width: 'calc(100vw - 45px)',
  maxWidth: '500px',
  frameBorder: '0',
  scrolling: 'no',
  border: 'none',
  overflow: 'hidden',
  margin: '-7px -10px -13px -16px',
}

class TelegramPost extends Component {
  constructor(props) {
    super(props)

    this.state = {
      src: this.props.src,
      id: '',
      height: '80px'
    }
    this.messageHandler = this.messageHandler.bind(this);
    this.urlObj = document.createElement('a')

  }

  componentDidMount() {
    window.addEventListener('message', this.messageHandler)

    this.iFrame.addEventListener('load', () => {

      this.checkFrame(this.state.id)
    })
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageHandler)
  }

  messageHandler({ data, source }) {
    if (!data || typeof data !== 'string' || source !== this.iFrame.contentWindow) {
      return
    }

    const action = JSON.parse(data)

    if (action.event === 'resize' && action.height) {
      this.setState({
        height: action.height + 'px',
      })
    }
  }

  checkFrame(id) {
    this.iFrame.contentWindow.postMessage(JSON.stringify({ event: 'visible', frame: id }), '*')
  }

  componentWillReceiveProps({ src }) {
    if (this.state.src !== src) {
      this.urlObj.href = src
      const id = `telegram-post${this.urlObj.pathname.replace(/[^a-z0-9_]/ig, '-')}`

      this.setState({ src, id }, () =>
        this.checkFrame(id)
      )
    }
  }

  render () {
    const { src, height } = this.state
    const { container, style = {} } = this.props

    return (
      <div
        data-sharing-id={container}
        style={{
          overflow: 'hidden',
          margin: '-10px -10px 0px -10px',
        }}
      >
        <iframe
          loading="lazy"
          ref={node => this.iFrame = node}
          src={src + '?embed=1&dark=1&userpic=false'}
          height={height}
          id={'telegram-post' + this.urlObj.pathname.replace(/[^a-z0-9_]/ig, '-')}
          style={{ ...defaultStyle, ...style }}
        />
      </div>
    )
  }
}

export default TelegramPost
