export async function create({ chatId, id, text }) {
  const response = await this.post(`/chats/${chatId}/messages`, {
    chat_message: { id, text },
  });

  return response;
}

export async function update({ chatId, id, text }) {
  const response = await this.patch(`/chats/${chatId}/messages/${id}`, {
    chat_message: { text },
  });

  return response;
}

export async function destroy({ chatId, id }) {
  const response = await this.delete(`/chats/${chatId}/messages/${id}`);

  return response;
}
