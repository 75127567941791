import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';

const Avatar = ({ icon: Icon, pending, size = 50 }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Icon
        sx={{
          backgroundColor: `var(--tg-theme-${pending ? 'secondary-' : ''}bg-color)`,
          color: 'var(--tg-theme-hint-color)',
          borderRadius: `${size}px`,
          border: `8px solid var(--tg-theme-${pending ? 'secondary-' : ''}bg-color)`,
          position: 'absolute',
          width: `${size}px`,
          height: `${size}px`,
        }}
      />
      {pending && (
        <CircularProgress
          disableShrink
          color="inherit"
          sx={{
            color: 'var(--tg-theme-hint-color)',
            opacity: 0.25,
            position: 'absolute',
            height: `${size}px !important`,
            width: `${size}px !important`,
          }}
        />
      )}
      {pending && (
        <Skeleton
          variant="circular"
          sx={{
            position: 'absolute',
            bgcolor: 'var(--tg-theme-hint-color)',
            filter: 'opacity(0.25)',
            width: `${size}px`,
            height: `${size}px`,
          }}
        />
      )}
    </div>
  );
};

export default Avatar;
