export async function all() {
  const response = await this.getPrecached('/channels');

  return response;
};

export async function search() {
  const response = await this.get('/chats/search');

  return response;
};
