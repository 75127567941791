import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import TelegramClient from 'lib/TelegramClient';
import { useAPI, useBackAction, useInterval, useTitle } from 'hooks';
import { Accounts, Centered } from 'components';
import {
  deleteAccount,
  refreshAccounts,
  selectAccounts,
} from 'slices/accounts';
import { hardSignout, signout } from 'slices/auth';

import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  ContactSupport as ContactSupportIcon,
  GroupRounded as GroupRoundedIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  PersonAddRounded as PersonAddRoundedIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';

const AccountsPage = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const accounts = useSelector(selectAccounts);
  const navigate = useNavigate();
  const { AuthAPI, BlobsAPI } = useAPI();

  const deleteAccount = (id) => {
    dispatch(deleteAccount([BlobsAPI, { id }]));
  };

  const hardLogout = () => {
    dispatch(hardSignout(AuthAPI));
    navigate('/', { replace: true });
  };

  useTitle('Хранилище аккаунтов');

  useBackAction(({ navigate }) => {
    navigate('/', { replace: true });
  });

  useEffect(() => {
    dispatch(refreshAccounts(BlobsAPI));
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            backgroundImage: 'none',
            backgroundColor: 'var(--tg-theme-bg-color)',
            boxShadow: '0px 0px 1px 0px rgb(0 0 0)',
            color: 'var(--tg-theme-button-text-color)',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              disabled={isMenuOpen}
              edge="start"
              onClick={() => setMenuOpen(true)}
              size="large"
              sx={{ mr: 2 }}
            >
              <Badge color="primary" variant="dot" invisible={true}>
                <MenuIcon />
              </Badge>
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Хранилище аккаунтов
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <SwipeableDrawer
        anchor='left'
        disableSwipeToOpen={true}
        open={isMenuOpen}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: 'min(100%, 300px)',
          },
        }}
      >
        <List
          sx={{
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '& .MuiListItemIcon-root': {
              minWidth: 30,
              ml: -0.5,
              mr: 1,
            },
          }}
        >
          {/*<ListItem disablePadding>
            <ListItemButton>
              <Avatar /> Profile
            </ListItemButton>
          </ListItem>
          <Divider />*/}
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/chats')}>
              <ListItemIcon>
                <AdminPanelSettingsIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Protected space" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ContactSupportIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Задать вопрос" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <SettingsIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Настройки" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={hardLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Выйти" />
            </ListItemButton>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <Accounts items={accounts} />
      <Fab
        aria-label="add"
        onClick={() => navigate('add')}
        sx={{
          backgroundColor: 'var(--tg-theme-button-color) !important',
          color: 'var(--tg-theme-button-text-color)',
          bottom: '20px',
          position: 'fixed',
          right: '20px',
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
}

export default AccountsPage;
