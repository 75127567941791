import React from 'react';
import { Centered, Chat } from 'components';
import './chats.css';

const Chats = ({ items }) => (
  <div className="chats">
    {items.map(chat => (
      <Chat key={chat.id} {...chat} />
    ))}
    {!items.length && (
      <Centered>
        Здесь нет ни одного чата
      </Centered>
    )}
  </div>
);

export default Chats;
