import { useRef } from 'react';

export const usePool = () => {
  const collectionRef = useRef([]);
  const getPool = () => collectionRef.current.map(JSON.parse);

  const poolItem = (item) => {
    collectionRef.current = [...new Set([...collectionRef.current, JSON.stringify(item)])];
  };

  const unpoolItem = (item) => {
    const serialized = JSON.stringify(item);
    collectionRef.current = [...new Set([...collectionRef.current.filter(i => i !== serialized)])];
  };

  return [getPool, poolItem, unpoolItem];
};
