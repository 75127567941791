import React from 'react';
import * as mui from '@mui/material';
import * as muiIcon from '@mui/icons-material';

export const Safety = (props) => {
  const { vpnRequired, incognitoRequired, httpsOnlyRequired } = props;

  return (
    <mui.Stack
      p="20px 10px"
      spacing={7}
      minWidth="300px"
      width="90vw"
      justifyContent="center"
      height="100vh"
    >
      <mui.Divider>
        <mui.Chip sx={{ color: 'white', backgroundColor: 'rgba(255,255,255,0.15)' }} label="Режим Инкогнито" size="small" />
      </mui.Divider>

      <mui.Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {incognitoRequired ? (
          <muiIcon.Error fontSize="large" color="error" />
        ) : (
          <muiIcon.CheckCircle fontSize="large" color="success" />
        )}
        <mui.Typography variant="h5">
          {incognitoRequired ? 'Перейдите в режим Инкогнито' : 'Режим инкогнито активен'}
        </mui.Typography>
      </mui.Box>

      <mui.Divider>
        <mui.Chip sx={{ color: 'white', backgroundColor: 'rgba(255,255,255,0.15)' }} label="Режим HTTPS-only" size="small" />
      </mui.Divider>

      <mui.Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {httpsOnlyRequired ? (
          <muiIcon.Error fontSize="large" color="error" />
        ) : (
          <muiIcon.CheckCircle fontSize="large" color="success" />
        )}
        <mui.Typography variant="h5">
          {httpsOnlyRequired ? 'Настройте HTTPS-only в браузере' : 'Используется протокол HTTPS'}
        </mui.Typography>
      </mui.Box>

      <mui.Divider>
        <mui.Chip sx={{ color: 'white', backgroundColor: 'rgba(255,255,255,0.15)' }} label="Подключение к VPN" size="small" />
      </mui.Divider>

      <mui.Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {vpnRequired ? (
          <muiIcon.Error fontSize="large" color="error" />
        ) : (
          <muiIcon.CheckCircle fontSize="large" color="success" />
        )}
        <mui.Typography variant="h5">
          {vpnRequired ? 'Подключитесь к VPN сервису' : 'Подключение к VPN активно'}
        </mui.Typography>
      </mui.Box>
    </mui.Stack>
  );
};
