export const resizeBase64Image = async (base64Str, maxHeight = 200, maxWidth = 200) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.addEventListener('load', (data) => {
      const canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      resolve(canvas.toDataURL());
    })
    img.src = base64Str;
  });
}
