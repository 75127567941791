import React, { useEffect, useRef, useState } from 'react';
import './login.css';
import { useInterval } from 'hooks';
import { isDesktop, isMobile } from 'utils/device';

const PasswordStep = ({ onComplete }) => {
  const passwordInputRef = useRef(null);
  const [password, setPassword] = useState(process.env.REACT_APP_AUTH_PASSWORD || '');

  const limitPassword = (p) => p.replace(/\D/g, '').slice(0, 4);

  useInterval(() => {
    if (isMobile()) {
      return;
    }

    passwordInputRef.current.setSelectionRange(password.length, password.length);
    passwordInputRef.current.focus();
  }, [password, passwordInputRef], 100);

  useEffect(() => {
    if (password.length === 4) {
      onComplete(password)
    }
  }, [password]);

  return (
    <div className="login">
      <p style={{ height: '80px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        Наберите код или придумайте новый
      </p>

      <div style={{ color: 'var(--tg-theme-hint-color)', height: '50px', caretColor: 'transparent', letterSpacing: '10px', pointerEvents: 'none', textAlign: 'center', position: 'relative' }}>
        <input
          autoFocus={isDesktop()}
          onChange={({ target: { value } }) => setPassword(p => limitPassword(value))}
          ref={passwordInputRef}
          style={{ opacity: 0.01, color: 'var(--tg-theme-hint-color)', height: '50px', caretColor: 'transparent', letterSpacing: '10px', pointerEvents: 'none', textAlign: 'center' }}
          type="text"
          value={(password + '____').slice(0, 4)}
        />

        <span style={{ background: 'var(--tg-theme-bg-color)', position: 'absolute', top: 0, height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', left: 0, fontSize: '22px', color: 'var(--tg-theme-hint-color)', height: '50px', caretColor: 'transparent', letterSpacing: '10px', pointerEvents: 'none', textAlign: 'center' }}>
          {('****'.slice(0, Math.max(password.length - 1, 0)) + (password.at(-1) || '') + '____').slice(0, 4)}
        </span>
      </div>

      <div className="numbers-panel-wrapper">
        <div className="numbers-panel">
          <div className="numbers-panel__item">
            <button onClick={() => setPassword(p => limitPassword(p + "1"))}>
              1
            </button>
          </div>
          <div className="numbers-panel__item">
            <button onClick={() => setPassword(p => limitPassword(p + "2"))}>
              2
            </button>
          </div>
          <div className="numbers-panel__item">
            <button onClick={() => setPassword(p => limitPassword(p + "3"))}>
              3
            </button>
          </div>
          <div className="numbers-panel__item">
            <button onClick={() => setPassword(p => limitPassword(p + "4"))}>
              4
            </button>
          </div>
          <div className="numbers-panel__item">
            <button onClick={() => setPassword(p => limitPassword(p + "5"))}>
              5
            </button>
          </div>
          <div className="numbers-panel__item">
            <button onClick={() => setPassword(p => limitPassword(p + "6"))}>
              6
            </button>
          </div>
          <div className="numbers-panel__item">
            <button onClick={() => setPassword(p => limitPassword(p + "7"))}>
              7
            </button>
          </div>
          <div className="numbers-panel__item">
            <button onClick={() => setPassword(p => limitPassword(p + "8"))}>
              8
            </button>
          </div>
          <div className="numbers-panel__item">
            <button onClick={() => setPassword(p => limitPassword(p + "9"))}>
              9
            </button>
          </div>
          <div/>
          <div className="numbers-panel__item">
            <button onClick={() => setPassword(p => limitPassword(p + "0"))}>
              0
            </button>
          </div>
          <div className="numbers-panel__item">
            <button onClick={() => setPassword(p => p.slice(0, -1))}>
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 489.425 489.425" transform="translate(-2 0)" style={{ enableBackground: 'new 0 0 489.425 489.425', height: 'calc(min((100vh - 80px - 50px - 5 * 20px) / 4, (100vw - 4 * 20px) / 3) / 1.75)' }}>
                <path d="M122.825,394.663c17.8,19.4,43.2,30.6,69.5,30.6h216.9c44.2,0,80.2-36,80.2-80.2v-200.7c0-44.2-36-80.2-80.2-80.2h-216.9
                  c-26.4,0-51.7,11.1-69.5,30.6l-111.8,121.7c-14.7,16.1-14.7,40.3,0,56.4L122.825,394.663z M29.125,233.063l111.8-121.8
                  c13.2-14.4,32-22.6,51.5-22.6h216.9c30.7,0,55.7,25,55.7,55.7v200.6c0,30.7-25,55.7-55.7,55.7h-217c-19.5,0-38.3-8.2-51.5-22.6
                  l-111.7-121.8C23.025,249.663,23.025,239.663,29.125,233.063z" fill="var(--tg-theme-hint-color)"/>
                <path d="M225.425,309.763c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l47.8-47.8l47.8,47.8c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6
                  c4.8-4.8,4.8-12.5,0-17.3l-47.9-47.8l47.8-47.8c4.8-4.8,4.8-12.5,0-17.3s-12.5-4.8-17.3,0l-47.8,47.8l-47.8-47.8
                  c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l47.8,47.8l-47.8,47.8C220.725,297.263,220.725,304.962,225.425,309.763z" fill="var(--tg-theme-hint-color)"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordStep;
