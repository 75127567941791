import { useState, useLayoutEffect, useRef } from 'react';

export const useIntersectionObserver = ({
  onObserve, onUnobserve, root, rootMargin, threshold
}) => {
  const [isVisible, setState] = useState(false);
  const elementRef = useRef();

  useLayoutEffect(() => {
    if (!root) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
        if (entry.isIntersecting) {
          onObserve && onObserve();
        } else {
          onUnobserve && onUnobserve();
        }
      },
      {
        root,
        rootMargin,
        threshold
      }
    );

    const currentRef = elementRef.current;

    currentRef && observer.observe(currentRef);

    return () => currentRef && observer.unobserve(currentRef);
  }, [elementRef, onObserve, onUnobserve, root, rootMargin, threshold]);

  return [isVisible, elementRef];
};
