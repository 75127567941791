export async function generateSalt(data) {
  const response = await this.post('/auth/salt', data);

  return response;
};

export async function create({ id, password }) {
  const response = await this.post('/auth', { id, password });

  return response;
};

export async function update(params) {
  const response = await this.patch('/auth', params);

  return response;
};

export async function destroy() {
  const response = await this.delete('/auth');

  return response;
};
