import S3BasedAPIGatewayClient from 's3-api-gateway-sdk';
import TransparentAPIGateway from '../TransparentAPIGateway';

class S3BasedAPIGateway {
  constructor() {
    const config = {
      clientId: process.env.REACT_APP_S3_BASED_API_GATEWAY_CLIENT_ID,
    };

    if (process.env.REACT_APP_S3_BASED_API_GATEWAY_USE_LOGS) {
      config.logger = console;
    }

    this.headers = { 'Content-Type': 'application/json' };
    this.instance = new S3BasedAPIGatewayClient(config);
  }

  static async getPrecached(path) {
    const baseUrl = `${process.env.REACT_APP_PUBLIC_URL}/public`;
    const response = await TransparentAPIGateway.get([baseUrl, path].join(''));

    return response;
  }

  static async get(path, params = {}, headers = {}) {
    const gateway = new S3BasedAPIGateway();
    const response = await gateway.instance.get(
      path,
      params,
      { ...gateway.headers, ...headers }
    );

    return response;
  }

  static async post(path, params = {}, headers = {}) {
    const gateway = new S3BasedAPIGateway();
    const response = await gateway.instance.post(
      path,
      params,
      { ...gateway.headers, ...headers }
    );

    return response;
  }

  static async patch(path, params = {}, headers = {}) {
    const gateway = new S3BasedAPIGateway();
    const response = await gateway.instance.patch(
      path,
      params,
      { ...gateway.headers, ...headers }
    );

    return response;
  }

  static async delete(path, params = {}, headers = {}) {
    const gateway = new S3BasedAPIGateway();
    const response = await gateway.instance.delete(
      path,
      params,
      { ...gateway.headers, ...headers }
    );

    return response;
  }
}

export default S3BasedAPIGateway;
