import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectToken } from 'slices/auth';
import * as apis from 'api';
import APIGateway from 'api/gateway';

export const useAPI = () => {
  const token = useSelector(selectToken);
  const navigate = useNavigate();

  const Authorization = token ? `Bearer ${token}` : null;

  const patchedAPIGateway = Object.fromEntries(
    ['getPrecached', 'get', 'post', 'patch', 'delete'].map(methodName => (
      [
        methodName,
        async (requestPath, requestParams = {}, requestHeaders = {}) => {
          const { data, headers, status } = await APIGateway[methodName](
            requestPath,
            requestParams,
            { Authorization, ...requestHeaders },
          );

          if (status >= 400) {
            navigate(-1);
          }

          if (status >= 400) {
            throw `${methodName.toUpperCase()} ${requestPath} ${status}`;
          }

          return data;
        },
      ]
    ))
  );

  const patchedAPIs = Object.fromEntries(
    Object.entries(apis).map(([name, api]) => (
      [
        name,
        Object.fromEntries(
          Object.entries(api).map(([methodName, method]) => (
            [
              methodName,
              method.bind(patchedAPIGateway),
            ]
          ))
        ),
      ]
    ))
  );

  return patchedAPIs;
};
