import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hardSignout, signout } from 'slices/auth';
import { selectChannels } from 'slices/channels';
import { getRandomUuid } from 'utils/uuid';
import { isSameObject } from 'utils/comparators';

const initialState = {
  collection: null,
  status: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   'auth/fetchCount',
//   async (amount) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );
export const refreshChat = createAsyncThunk(
  'chat/pull',
  async ([chatsAPI, id], { getState }) => {
    const state = getState();
    const channels = selectChannels(state);
    const isPublicChannel = !!channels.find(c => c.id === id)
    const methodName = isPublicChannel ? 'getPrecached' : 'get';
    const data = await chatsAPI[methodName](id);

    return data;
  }
);

export const refreshChats = createAsyncThunk(
  'chats/pull',
  async (chatsAPI) => {
    const data = await chatsAPI.all();

    return data;
  }
);

export const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    sendMessage: (state, action) => {
      const { collection: chats } = state;
      const [MessagesAPI, { chatId, text }] = action.payload;

      const chat = chats.find((c) => c.id === chatId);
      const { membership } = chat;
      const sendingMessage = { id: getRandomUuid(), text, membership };

      MessagesAPI.create({ chatId, ...sendingMessage });
      chat.sendingMessages ||= [];
      chat.sendingMessages.push(sendingMessage);
      chat.last_read_message_id = sendingMessage.id;
      chat.last_viewed_message_id = sendingMessage.id;
    },
    updateChat: (state, action) => {
      const { collection: chats } = state;
      const [ChatsAPI, { id, name, photoUrl }] = action.payload;

      const chat = chats.find((c) => c.id === id);

      ChatsAPI.update({ id, name, photoUrl });
      chat.name = name;
      chat.photoUrl = photoUrl;
    },
    updateMembership: (state, action) => {
      const { collection: chats } = state;
      const [ChatMembershipsAPI, { chatId, ...params }] = action.payload;

      const chat = chats.find((c) => c.id === chatId);
      const membership = { ...chat.membership, ...params };

      if (isSameObject(chat.membership, membership)) return;

      ChatMembershipsAPI.update(chatId, membership);
      chat.membership = membership;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(signout.pending, (state) => {
        state.collection = null;
        state.status = null;
      })
      .addCase(hardSignout.pending, (state) => {
        state.collection = null;
        state.status = null;
      })
      .addCase(refreshChats.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(refreshChats.fulfilled, (state, action) => {
        state.collection = action.payload;
      })
      .addCase(refreshChat.fulfilled, (state, action) => {
        state.collection ||= [];
        const { collection: chats } = state;
        const { payload: chat } = action;
        const chatIndex = chats.findIndex((c) => c.id === chat.id);
        if (chatIndex === -1) {
          chats.push(chat);
        } else {
          chats[chatIndex] = {
            ...chat,
            sendingMessages: chats[chatIndex].sendingMessages?.filter((m) => (
              !chat.last_messages.map(({ id }) => id).includes(m.id)
            )),
          }
        }
      });
  },
});

export const {
  sendMessage,
  updateChat,
  updateMembership,
} = chatsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.auth.value)`
// export const selectCount = (state) => state.auth.value;

export const selectChats = (state) => state.chats.collection;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default chatsSlice.reducer;
