import { useEffect } from 'react';

export const useTitle = (title) => (
  useEffect(() => {
    const previousTitle = document.title;
    document.title = title;

    return () => document.title = previousTitle;
  }, [title])
);
