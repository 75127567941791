import stableJsonStringify from 'json-stable-stringify';

export const sha512 = async (obj) => {
  const json = stableJsonStringify(obj);
  const utf8 = new TextEncoder().encode(json);
  const hashBuffer = await crypto.subtle.digest('SHA-512', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};
