import axios from 'axios';

class TransparentAPIGateway {
  constructor() {
    const headers = { 'Content-Type': 'application/json' };

    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers,
    });

    this.instance.interceptors.response.use(
      response => Promise.resolve(response),
      error => {
        if (error.response) {
            return Promise.resolve(error.response);
        } else {
            return Promise.reject(error);
        }
      },
    );
  }

  static getPrecached(path) {
    const baseUrl = `${process.env.REACT_APP_PUBLIC_URL}/public`;

    return TransparentAPIGateway.get([baseUrl, path].join(''));
  }

  static get(url, data, headers) {
    return new TransparentAPIGateway().instance.request({ method: 'get', url, headers, data });
  }

  static post(url, data, headers) {
    return new TransparentAPIGateway().instance.request({ method: 'post', url, headers, data });
  }

  static patch(url, data, headers) {
    return new TransparentAPIGateway().instance.request({ method: 'patch', url, headers, data });
  }

  static delete(url, data, headers) {
    return new TransparentAPIGateway().instance.request({ method: 'delete', url, headers, data });
  }
}

export default TransparentAPIGateway;
