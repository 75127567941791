import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAPI, useBackAction, useInterval, useTitle } from 'hooks';
import { Centered } from 'components';
import {
  refreshChats,
  selectChats,
} from 'slices/chats';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const ChatsPage = () => {
  // const dispatch = useDispatch();
  // const chats = useSelector(selectChats);
  // const [chats, setChats] = useState(null);
  const { connectionId } = useParams();

  const navigate = useNavigate();

  const { ConnectionsAPI } = useAPI();

  useTitle('Подключение');

  useBackAction(({ navigate }) => {
    navigate('/', { replace: true });
  });

  useEffect(() => {
    ConnectionsAPI.join({ id: connectionId }).then(({ chat_id: chatId }) => {
      navigate(`/chats/${chatId}`, { replace: true });
    });
  }, []);

  // useEffect(() => {
  //   dispatch(refreshChats());
  // }, []);
  // useEffect(() => {
  //   ChatsAPI.all().then(setChats);
  // }, []);
  //
  // useInterval(() => {
  //   ChatsAPI.all().then(setChats);
  // }, [], 500);

  // return (
  //   <CodeStep onComplete={console.log} />
  // )

  return (
    <Centered>
      Подключение...
    </Centered>
  );
}

export default ChatsPage;
