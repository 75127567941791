import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Badge, Chip, Divider, Fab } from '@mui/material';
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
import { Centered, Message } from 'components';
import { useAPI, useIntersectionObserver, useInterval, usePool } from 'hooks';
import { updateMembership } from 'slices/chats';
import './messages.css';

const Messages = ({
  chatId,
  items,
  lastViewedMessageId: initialLastViewedMessageId,
  lastReadMessageId: initialLastReadMessageId,
}) => {
  const dispatch = useDispatch();
  const messageContainerRef = useRef();
  const [lastViewedMessageId, setLastViewedMessageId] = useState(initialLastViewedMessageId);
  const [lastReadMessageId, setLastReadMessageId] = useState(initialLastReadMessageId);
  const [getPool, poolItem, unpoolItem] = usePool();
  const { ChatMembershipsAPI } = useAPI();

  const indexOfLastReadMessage = items.map(({ id }) => id).indexOf(lastReadMessageId);
  const lastMessagesCount = items.length;

  useLayoutEffect(() => {
    // setLastViewedMessageId((id) => id ? id : initialLastViewedMessageId);
    setLastViewedMessageId(initialLastViewedMessageId);
  }, [initialLastViewedMessageId]);

  useLayoutEffect(() => {
    // setLastReadMessageId((id) => id ? id : initialLastReadMessageId);
    setLastReadMessageId(initialLastReadMessageId);
  }, [initialLastReadMessageId]);

  // console.log('Messages component rerender')

  let unreadCount;
  if (lastMessagesCount === 0) {
    unreadCount = 0;
  } else if (indexOfLastReadMessage === -1 && lastReadMessageId != null) {
    unreadCount = Infinity;
  } else {
    unreadCount = lastMessagesCount - (indexOfLastReadMessage + 1);
  }

  const isJumpAvailable = items.every(i => !i.fake) && !!(unreadCount || lastViewedMessageId != lastReadMessageId);
  const jump = () => {
    let messageIdToJump;
    if (unreadCount && lastViewedMessageId != lastReadMessageId) {
      messageIdToJump = lastReadMessageId;
    } else {
      messageIdToJump = items.at(-1)?.id;
    }
    const element = document.getElementById(`message-${messageIdToJump}`);
    element?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
  };

  // TODO
  useInterval(() => {
    const pool = getPool();
    const observingMessage = items.filter(({ id: itemId, created_at: itemCreatedAt }) => (
      pool.some(({ id, created_at }) => id === itemId && created_at === itemCreatedAt)
    )).at(-1);

    const observingMessageId = observingMessage?.id;
    setLastViewedMessageId(observingMessageId);
    const indexOfLastReadMessage = items.map(({ id }) => id).indexOf(lastReadMessageId);
    const indexOfObservingMessage = items.map(({ id }) => id).indexOf(observingMessageId);
    if (indexOfObservingMessage > indexOfLastReadMessage) {
      setLastReadMessageId(observingMessageId);
    }
    if (observingMessageId) {
      dispatch(updateMembership([ChatMembershipsAPI, {
        chatId,
        observingMessageId,
      }]));
    }
  }, [lastReadMessageId], 100);

  useLayoutEffect(() => {
    if (initialLastViewedMessageId == null) return;
    const fallbackLastReadMessageId = items.at(-1)?.id;

    const element = (
      document.getElementById(`message-${initialLastViewedMessageId}`)
      || document.getElementById(`message-${fallbackLastReadMessageId}`)
    );
    element?.scrollIntoView({ block: 'end', inline: 'end' });
  }, [initialLastViewedMessageId]);

  const [isFirstMessageVisible, firstMessageRef] = useIntersectionObserver({
    root: messageContainerRef.current,
    rootMargin: '100%',
  })

  const [isLastMessageVisible, lastMessageRef] = useIntersectionObserver({
    root: messageContainerRef.current,
    rootMargin: '100%',
  })

  useEffect(() => {
    if (!isFirstMessageVisible) {
      return;
    }

    console.log('isFirstMessageVisible', isFirstMessageVisible)
  }, [isFirstMessageVisible]);

  useEffect(() => {
    if (!isLastMessageVisible) {
      return;
    }

    console.log('isLastMessageVisible', isLastMessageVisible)
  }, [isLastMessageVisible]);

  const getMessageRef = (index) => {
    switch (index) {
      case 0:
        return firstMessageRef;
      case items.length - 1:
        return lastMessageRef;
    }
  };

  return (
    <>
      <div className="messages" ref={messageContainerRef}>
        {items.map((message, index) => {
          const nextFromSameMember = index === items.length - 1 ? false : items[index + 1].membership.id === message.membership.id;
          const previousFromSameMember = index === 0 ? false : items[index - 1].membership.id === message.membership.id;

          const dateString = new Date(message.created_at).toDateString();
          const previousDateString = new Date(items[index - 1]?.created_at).toDateString();

          const shoudShowDateSeparator = message.created_at && (index === 0 || dateString !== previousDateString);

          return (
            <>
              {shoudShowDateSeparator && (
                <Centered height="auto">
                  {dateString}
                </Centered>
              )}
              <div key={message.id} ref={getMessageRef(index)}>
                <Message
                  {...message}
                  nextFromSameMember={nextFromSameMember}
                  previousFromSameMember={!shoudShowDateSeparator && previousFromSameMember}
                  chatId={chatId}
                  onObserve={(m) => poolItem(m)}
                  onUnobserve={(m) => unpoolItem(m)}
                />
              </div>
            </>
          );
        })}
        {!items.length && (
          <Centered>
            Здесь нет ни одного сообщения
          </Centered>
        )}
      </div>
      {isJumpAvailable && (
        <div className="messages__jump">
          <Badge
            badgeContent={unreadCount}
            color="primary"
            sx={{
              bottom: '20px',
              flexShrink: 0,
              position: 'absolute',
              right: '20px',
              '& .MuiBadge-badge': {
                backgroundColor: 'var(--tg-theme-button-color) !important',
                color: 'var(--tg-theme-button-text-color)',
                right: '50%',
                zIndex: 1,
              }
            }}
          >
            <Fab
              aria-label="add"
              onClick={jump}
              sx={{
                backgroundColor: 'var(--tg-theme-bg-color) !important',
                color: 'var(--tg-theme-hint-color)',
                zIndex: 0,
              }}
            >
              <KeyboardArrowDownRoundedIcon />
            </Fab>
          </Badge>
        </div>
      )}
    </>
  );
};

export default Messages;
