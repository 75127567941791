import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import { Avatar as MuiAvatar, Chip } from '@mui/material';
import { Avatar, ChatIcon, ChatLogo, PublicationTime } from 'components';
import { getChatDisplayName } from 'utils/chats';
import { useAPI } from 'hooks';
import { selectEncryptionKey } from 'slices/auth';
import { deleteAccount, updateAccount } from 'slices/accounts';
import TelegramClient from 'lib/TelegramClient';
import './account.css';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PinIcon from '@mui/icons-material/PushPin';
import ReplyIcon from '@mui/icons-material/Reply';

const Account = (account) => {
  const encryptionKey = useSelector(selectEncryptionKey);
  const { id, data, type } = account;
  const { id: telegramId, name, phoneNumber, photoUrl, username } = TelegramClient.info(data, encryptionKey);

  const dispatch = useDispatch();
  const { BlobsAPI } = useAPI();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [signInCode, setSignInCode] = useState(null);
  const [isHidden, setHidden] = useState(false);
  const [isInitialized, setInitialized] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const clientRef = useRef();

  const { ConnectionsAPI } = useAPI();

  useEffect(() => {
    const handleAccountUpdate = (data) => {
      dispatch(updateAccount([BlobsAPI, {
        id,
        data,
        type: 'telegram_account',
      }]));
    };

    (async () => {
      setInitialized(false);
      const client = await TelegramClient.restore(data, encryptionKey);
      clientRef.current = client;

      client.on('updateChatLastMessage', ({ chat_id, last_message: { content } }) => {
        if (chat_id !== 777000) return;
        const code = content?.text?.text?.match(/\D(\d{5})\D/)?.at(1)
        if (code) setSignInCode(code);
      });

      client.on('ready', async () => {
        setInitialized(true);
        const backup = await client.backup();
        handleAccountUpdate(backup);
      });
    })();

    return () => clientRef.current?.close();
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleAccountDelete = () => {
    dispatch(deleteAccount([BlobsAPI, { id: account.id }]));
    setHidden(true);
  };

  return (
    <>
      <div style={{ cursor: 'pointer' }} key={account.id} onClick={() => isInitialized && setDialogOpen(true)}>
        <div className="account" onContextMenu={handleContextMenu}>
          <div className="account__logo">
            <ChatLogo { ...{ photo_url: photoUrl } } />
          </div>
          <div className="account__main">
            <div className="account__heading">
              <ChatIcon type='direct' />
                <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  {name}
                </span>
              </div>
              <div className="account__rest">
                <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  {isInitialized ? (
                    <>
                      {phoneNumber ? `+${phoneNumber}` : 'Номер неизвестен'}{username ? `, @${username}` : ''}
                    </>
                  ): 'Запуск, подождите...'}
                </span>
              </div>
            </div>
            <div className="account__stats">
              <div className="account__time">
                {account && (
                  <PublicationTime timestamp={account?.created_at} />
                )}
              </div>
              <div className="account__unread">
                {signInCode ? (
                  <Chip
                    color="info"
                    label={<>
                      <LockOpenIcon fontSize='12px' sx={{ marginLeft: '-2px', marginBottom: '-1px', marginRight: '2px' }} />
                      {signInCode}
                    </>}
                    size="small"
                    sx={{
                      backgroundColor: 'var(--tg-theme-button-color) !important',
                      color: 'var(--tg-theme-button-text-color)',
                      fontSize: '12px',
                      minHeight: '24px',
                      minWidth: '24px',
                    }}
                  />
                ) : (isInitialized ? '' : (
                  <CircularProgress
                    size={24}
                    sx={{ color: 'var(--tg-theme-hint-color)' }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Удалить из хранилища</ListItemText>
          </MenuItem>
        </Menu>
        <Dialog sx={{ margin: '-22px' }} open={isDialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle
            sx={{
              padding: '10px 20px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >Вход в аккаунт {name}
        </DialogTitle>
        <DialogContent sx={{ padding: '0px 20px' }}>
          <DialogContentText sx={{ marginBottom: '0px' }}>
            Используйте номер для входа:
          </DialogContentText>
          <TextField
            autoFocus={!signInCode}
            inputProps={{
              readOnly: true,
              onFocus: ({ target }) => {
                target.setSelectionRange(0, target.value.length);
                if (navigator.clipboard) {
                  navigator.clipboard.writeText(target.value);
                }
              },
            }}
            fullWidth
            defaultValue={phoneNumber}
          />
          <DialogContentText sx={{ margin: '20px 0 0 0' }}>
            {signInCode ? 'Используйте следующий код:' : 'Код для входа появится ниже:'}
          </DialogContentText>
          <TextField
            autoFocus={!!signInCode}
            inputProps={{
              readOnly: true,
              onFocus: ({ target }) => {
                target.setSelectionRange(0, target.value.length);
                if (navigator.clipboard) {
                  navigator.clipboard.writeText(target.value);
                }
              },
            }}
            sx={{ opacity: signInCode ? 1 : 0.25 }}
            label={signInCode ? null : 'Начинайте входить...'}
            fullWidth
            disabled={!signInCode}
            value={signInCode || ''}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
              setSignInCode(null);
              clientRef.current?.send('viewMessages', { chat_id: 777000, force: true });
            }}
          >
            {signInCode ? 'Подтвердить' : 'Закрыть'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Удалить из хранилища
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Вы точно хотите удалить аккаунт из хранилища?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsDeleteDialogOpen(false)}
          >
            Отмена
          </Button>
          <Button
            autoFocus
            color="error"
            onClick={handleAccountDelete}
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Account;
