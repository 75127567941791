import { useEffect } from 'react';
import { sleep } from 'utils/sleep';

export const usePageLeave = (callback, dependencies) => {
  useEffect(() => {
    const handler = () => {
      if (document.hidden) callback();
    };

    window.addEventListener('visibilitychange', handler);

    return () => window.removeEventListener('visibilitychange', handler);
  }, dependencies);
};
