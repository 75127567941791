import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import TelegramClient from 'lib/TelegramClient';
import { useAPI, useBackAction, useInterval, useTitle } from 'hooks';
import { getRandomUuid } from 'utils/uuid';
import { Centered } from 'components';
import { selectEncryptionKey } from 'slices/auth';
import { updateAccount } from 'slices/accounts';

import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const AddAccountPage = () => {
  const encryptionPassword = useSelector(selectEncryptionKey);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { BlobsAPI } = useAPI();

  const [page, setPage] = useState('number');
  const clientRef = useRef();
  const numberRef = useRef();
  const codeRef = useRef();
  const passwordRef = useRef();
  const [loading, setLoading] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isRestHidden, setRestHidden] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const next = () => {
    if (page === 'number') {
      setLoading(true);
      setRestHidden(false);
      clientRef.current.setPhoneNumber(numberRef.current.value).then((e) => {
        setNumberError(false);
        setLoading(false);
        setRestHidden(false);
      }).catch((e) => {
        setLoading(false);
        setNumberError(true);
      });
    } else if (page === 'code') {
      setLoading(true);
      setRestHidden(false);
      clientRef.current.setCode(codeRef.current.value).then((e) => {
        setCodeError(false);
        setLoading(false);
        setRestHidden(false);
      }).catch((e) => {
        setLoading(false);
        setCodeError(true);
      });
    } else if (page === 'password') {
      setLoading(true);
      setRestHidden(false);
      clientRef.current.setPassword(passwordRef.current.value).then((e) => {
        setPasswordError(false);
        setLoading(false);
        setRestHidden(false);
      }).catch((e) => {
        setLoading(false);
        setPasswordError(true);
      });
    } else if (page === 'ready') {
      navigate('/accounts', { replace: true });
    }
  };

  const handleAccountUpdate = (data) => {
    const id = getRandomUuid();
    dispatch(updateAccount([BlobsAPI, {
      id,
      data,
      type: 'telegram_account',
    }]));
  };

  useTitle('Добавление аккаунта');

  useBackAction(({ navigate }) => {
    navigate('/accounts', { replace: true });
  });

  useEffect(() => {
    const client = new TelegramClient({ encryptionPassword });
    clientRef.current = client;
    client.on('ready', async () => {
      const backup = await client.backup();
      handleAccountUpdate(backup);
      setPage('ready');
    });
    client.on('waitPhoneNumber', () => {
      setPage('number');
    });
    client.on('waitCode', () => {
      setPage('code');
    });
    client.on('waitPassword', ({ authorization_state: { password_hint } }) => {
      setPage('password');
    });

    return () => client.close();
  }, []);

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={() => window.history.back()}
        sx={{
          opacity: 0.5,
          top: '10px',
          position: 'fixed',
          left: '20px',
        }}
      >
        <ArrowBackIcon />
      </IconButton>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: isRestHidden ? '50%' : '100%', transition: 'all 500ms', flexWrap: 'wrap', maxWidth: '400px', margin: '0 auto', width: '100%', padding: '10px 10px 60px 10px' }}>
        {page == 'number' && (
          <div>
            <div style={{ display: 'flex', marginBottom: isRestHidden ? '60px' : '40px', transition: 'all 500ms', justifyContent: 'center' }}>
              <img style={{ width: isRestHidden ? '0%' : '100%', height: isRestHidden ? '0%' : '100%', transition: 'all 500ms', maxWidth: '150px', maxHeight: '150px' }} src={`${process.env.PUBLIC_URL}/telegram.svg`} />
            </div>
            <div style={{ display: 'flex', marginBottom: '10px', fontSize: '30px', fontWeight: '600', justifyContent: 'center' }}>
              Вход в Telegram
            </div>
            <div style={{ display: 'flex', marginBottom: '50px', opacity: 0.5, textAlign: 'center', justifyContent: 'center' }}>
              Войдите в аккаунт, который хотите добавить
            </div>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel htmlFor="outlined-adornment-number">Номер, на который зарегистрирован аккаунт</InputLabel>
              <OutlinedInput
                disabled={loading}
                onKeyPress={({ key }) => key === 'Enter' && next()}
                error={numberError}
                inputRef={numberRef}
                autoComplete="off"
                id="outlined-adornment-number"
                inputProps={{
                  pattern: '^\d+$',
                  type: 'number',
                  min: 0,
                  onFocus: ({ target }) => setRestHidden(true),
                  onBlur: ({ target }) => setRestHidden(false),
                }}
                startAdornment={<InputAdornment position="start">+</InputAdornment>}
                label="Номер, на который зарегистрирован аккаунт"
                onChange={({ target }) => {
                  target.value = (target.value || '').replace(/\D/g,'');
                  setNumberError(false);
                }}
              />
            </FormControl>
          </div>
        )}
        {page == 'code' && (
          <div>
            <div style={{ display: 'flex', marginBottom: isRestHidden ? '60px' : '40px', transition: 'all 500ms', justifyContent: 'center' }}>
              <img style={{ width: isRestHidden ? '0%' : '100%', height: isRestHidden ? '0%' : '100%', transition: 'all 500ms', maxWidth: '150px', maxHeight: '150px' }} src={`${process.env.PUBLIC_URL}/code.png`} />
            </div>
            <div style={{ display: 'flex', marginBottom: '10px', fontSize: '30px', fontWeight: '600', justifyContent: 'center' }}>
              {clientRef.current?.phoneNumber ? `+${clientRef.current.phoneNumber}` : ''}
            </div>
            <div style={{ display: 'flex', marginBottom: '50px', opacity: 0.5, textAlign: 'center', justifyContent: 'center' }}>
              Введите код, который прислал Telegram
            </div>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel htmlFor="outlined-adornment-number">5-циферный код для входа в аккаунт</InputLabel>
              <OutlinedInput
                disabled={loading}
                onKeyPress={({ key }) => key === 'Enter' && next()}
                error={codeError}
                inputRef={codeRef}
                autoComplete="off"
                id="outlined-adornment-number"
                label="5-циферный код для входа в аккаунт"
                inputProps={{
                  pattern: '^\d+$',
                  type: 'number',
                  min: 0,
                  max: 99999,
                  onFocus: ({ target }) => setRestHidden(true),
                  onBlur: ({ target }) => setRestHidden(false),
                }}
                onChange={({ target }) => {
                  target.value = (target.value || '').replace(/\D/g, '').slice(0, 5);
                  setCodeError(false);
                }}
              />
            </FormControl>
          </div>
        )}
        {page == 'password' && (
          <div>
            <div style={{ display: 'flex', marginBottom: isRestHidden ? '60px' : '40px', transition: 'all 500ms', justifyContent: 'center' }}>
              <img style={{ width: isRestHidden ? '0%' : '100%', height: isRestHidden ? '0%' : '100%', transition: 'all 500ms', maxWidth: '150px', maxHeight: '150px' }} src={`${process.env.PUBLIC_URL}/monkey.png`} />
            </div>
            <div style={{ display: 'flex', marginBottom: '10px', fontSize: '30px', fontWeight: '600', justifyContent: 'center' }}>
              Пароль для входа
            </div>
            <div style={{ display: 'flex', marginBottom: '50px', opacity: 0.5, textAlign: 'center', justifyContent: 'center' }}>
              Введите ваш двухфакторный пароль
            </div>
            <FormControl sx={{ width: '100%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                {clientRef.current?.authorizationState?.password_hint ? `Подсказка: ${clientRef.current.authorizationState.password_hint}` : 'Двухфакторный пароль'}
              </InputLabel>
              <OutlinedInput
                disabled={loading}
                onKeyPress={({ key }) => key === 'Enter' && next()}
                error={passwordError}
                inputRef={passwordRef}
                autoComplete="off"
                id="outlined-adornment-password"
                inputProps={{
                  onFocus: ({ target }) => setRestHidden(true),
                  onBlur: ({ target }) => setRestHidden(false),
                }}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={clientRef.current?.authorizationState?.password_hint ? `Подсказка: ${clientRef.current.authorizationState.password_hint}` : 'Двухфакторный пароль'}
                onChange={() => {
                  setPasswordError(false);
                }}
              />
            </FormControl>
          </div>
        )}
        {page == 'ready' && (
          <div>
            <div style={{ display: 'flex', marginBottom: '40px', justifyContent: 'center' }}>
              <img style={{ width: '100%', height: '100%', maxWidth: '150px', maxHeight: '150px' }} src={`${process.env.PUBLIC_URL}/check.png`} />
            </div>
            <div style={{ display: 'flex', marginBottom: '15px', fontSize: '30px', fontWeight: '600', justifyContent: 'center' }}>
              Аккаунт добавлен
            </div>
            <div style={{ display: 'flex', marginBottom: '20px', opacity: 0.5, textAlign: 'center', justifyContent: 'center' }}>
              Теперь вы можете выйти из вашего Telegram аккаунта и входить обратно, получая здесь код
            </div>
            <div style={{ display: 'flex', marginBottom: '50px', opacity: 0.5, textAlign: 'center', justifyContent: 'center' }}>
              Telegram прислал вам информацию о новом входе в аккаунт, название - "Encrypted account vault"
            </div>
          </div>
        )}
      </Box>

      <Fab
        aria-label="add"
        disabled={loading}
        onClick={next}
        sx={{
          backgroundColor: loading ? 'transparent !important' : 'var(--tg-theme-button-color) !important',
          color: 'var(--tg-theme-button-text-color)',
          bottom: '20px',
          position: 'fixed',
          right: '20px',
          zIndex: 1,
          height: isRestHidden ? '0px' : '56px',
          width: isRestHidden ? '0px' : '56px',
          transition: 'all 500ms',
        }}
      >
        <ArrowForwardIcon
          sx={{
            height: isRestHidden ? '0px' : '24px',
            width: isRestHidden ? '0px' : '24px',
            transition: 'all 500ms',
          }}
        />
      </Fab>
      {loading && (
        <CircularProgress
          size={56}
          sx={{
            color: 'var(--tg-theme-hint-color)',
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 2,
          }}
        />
      )}
    </>
  );
}

export default AddAccountPage;
