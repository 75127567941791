import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hardSignout, signout } from 'slices/auth';

const initialState = {
  collection: null,
  status: null,
};

export const refreshAccounts = createAsyncThunk(
  'accounts/pull',
  async (blobsAPI) => {
    const data = await blobsAPI.all({ types: 'telegram_account' });

    return data;
  }
);

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    updateAccount: (state, action) => {
      const [BlobsAPI, { id, data, type }] = action.payload;

      BlobsAPI.update({ id, data, type }).then(refreshAccounts);

      const account = state.collection?.find((a) => a.id === id);
      if (account) {
        account.data = data;
        account.type = type;
      } else {
        state.collection = [{ id, data, type }, ...(state.collection || [])];
      }
    },
    deleteAccount: (state, action) => {
      const [BlobsAPI, { id }] = action.payload;

      BlobsAPI.destroy({ id }).then(refreshAccounts);
      state.collection = state.collection?.filter((a) => a.id !== id) || [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signout.pending, (state) => {
        state.collection = null;
        state.status = null;
      })
      .addCase(hardSignout.pending, (state) => {
        state.collection = null;
        state.status = null;
      })
      .addCase(refreshAccounts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(refreshAccounts.fulfilled, (state, action) => {
        state.collection = action.payload;
      });
  },
});

export const {
  updateAccount,
  deleteAccount,
} = accountsSlice.actions;

export const selectAccounts = (state) => state.accounts.collection;

export default accountsSlice.reducer;
