import { configureStore } from '@reduxjs/toolkit';
import accountsReducer from 'slices/accounts';
import authReducer from 'slices/auth';
import chatsReducer from 'slices/chats';
import channelsReducer from 'slices/channels';

export const store = configureStore({
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware({
      serializableCheck: false,
    })
  ),
  reducer: {
    accounts: accountsReducer,
    auth: authReducer,
    chats: chatsReducer,
    channels: channelsReducer,
  },
});
