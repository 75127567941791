export async function update(chatId, { name, observingMessageId, photoUrl }) {
  const response = await this.patch(`/chats/${chatId}/membership`, {
    chat_membership: {
      name,
      observing_message_id: observingMessageId,
      photo_url: photoUrl,
    },
  });

  return response;
}
