import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAPI, useBackAction, useInterval, useTitle } from 'hooks';
import { sha512 } from 'utils/crypto';
import { getRandomUuid } from 'utils/uuid';
import { redirectToSafePage } from 'utils/redirect';
import { hardSignout, selectEncryptionKey, selectPicture, selectUser, signout, updateAuth } from 'slices/auth';
import { refreshChats, selectChats } from 'slices/chats';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  AdminPanelSettings as AdminPanelSettingsIcon,
  Computer as ComputerIcon,
  ContactSupport as ContactSupportIcon,
  FilterNone as FilterNoneIcon,
  GroupRounded as GroupRoundedIcon,
  InstallMobile as InstallMobileIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  PersonAddRounded as PersonAddRoundedIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
  Telegram as TelegramIcon,
  YouTube as YouTubeIcon,
} from '@mui/icons-material';
import { Centered, Chats, ChannelsSearch } from 'components';

const ChatsPage = () => {
  const encryptionKey = useSelector(selectEncryptionKey);
  const dispatch = useDispatch();
  const chats = useSelector(selectChats);
  const user = useSelector(selectUser);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  // const [chats, setChats] = useState(null);

  const { AuthAPI, ChatsAPI, ConnectionsAPI, ExternalAppTokensAPI } = useAPI();

  const navigate = useNavigate();

  useTitle('Protected space');

  useBackAction(({ navigate }) => {
    if (isMenuOpen) return setMenuOpen(false);
    if (isSearchOpen) return setSearchOpen(false);

    dispatch(signout(AuthAPI));
    navigate('/', { replace: true });
  });

  useEffect(() => {
    dispatch(refreshChats(ChatsAPI));
  }, []);

  useInterval(() => {
    dispatch(refreshChats(ChatsAPI));
  }, [], 5000);

  const copyAndNotify = (text) => {
    navigator.clipboard.writeText(text);
    alert('Пригласительная ссылка скопирована');
  };

  const picture = useSelector(selectPicture);

  const handleConnect = async () => {
    const id = getRandomUuid({ picture });
    ConnectionsAPI.create({ id });

    // const url = `https://s3.amazonaws.com/i.i/i.html#/join/${id}`;
    const url = `https://in6.to/#/join/${id}`;
    const title = 'Приглашаю в Protected Space';

    const fallbackMessage = [title, url].join('\n');

    if ('share' in navigator) {
      const dataToShare = { title, url };

      if (navigator.canShare(dataToShare)) {
        try {
          await navigator.share(dataToShare);
        } catch (e) {}
      } else {
        copyAndNotify(fallbackMessage);
      }
    } else {
      copyAndNotify(fallbackMessage);
    }
  };

  const openAccountsVault = () => {
    navigate('/accounts');
  };

  const launchExternalControlledApp = async (domain, path) => {
    const { token } = await ExternalAppTokensAPI.create({ domain });
    const encryptionPassword = await sha512({ domain, encryptionKey });
    const externalAppWindow = window.open(`https://${domain}.in6.to/initializeExternalApp`);
    window.addEventListener("message", async (event) => {
      if (event.origin !== `https://${domain}.in6.to`) return;
      if (event.data === 'readyToInitialize') {
        externalAppWindow.postMessage({
          type: 'dataToRestore',
          path,
          token,
          encryptionPassword
        }, `https://${domain}.in6.to`);
      } else if (event.data === 'danger') {
        dispatch(signout(AuthAPI));
        navigate('/', { replace: true });
        redirectToSafePage();
        window.close(); // TODO: It works only with not changed history
      }
    });
  };

  const hardLogout = () => {
    dispatch(hardSignout(AuthAPI));
    navigate('/', { replace: true });
  };

  const createGroup = () => {
    const id = getRandomUuid({ picture });
    ChatsAPI.create({ id, type: 'group' });
    navigate(`/chats/${id}`);
  };

  const installPWA = () => {
    window.App.deferredInstallPrompt?.prompt();
    window.App.deferredInstallPrompt?.userChoice.then((choiceResult) => {
      if (choiceResult.outcome !== 'accepted') return;

      window.App.installed = true;
      window.App.deferredInstallPrompt = null;
    });
  };

  const toggleNotifications = async () => {
    if (user.notify) return dispatch(updateAuth([AuthAPI, { notify: false, firebase_token: null }]));
    if (await Notification.requestPermission() === 'denied') return;
    const firebaseToken = await window.App.getFirebaseToken();
    if (!firebaseToken) return;
    dispatch(updateAuth([AuthAPI, {
      firebase_token: firebaseToken,
      notify: true,
    }]));
  };

  if (!chats) {
    return (
      <Centered>
        Загрузка...
      </Centered>
    );
  }

  // () => navigate('/connect')

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            backgroundImage: 'none',
            backgroundColor: 'var(--tg-theme-bg-color)',
            boxShadow: '0px 0px 1px 0px rgb(0 0 0)',
            color: 'var(--tg-theme-button-text-color)',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              disabled={isMenuOpen}
              edge="start"
              onClick={() => setMenuOpen(true)}
              size="large"
              sx={{ mr: 2 }}
            >
              <Badge color="primary" variant="dot" invisible={true}>
                <MenuIcon />
              </Badge>
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Protected space
            </Typography>
            <IconButton
              color="inherit"
              disabled={isSearchOpen}
              edge="end"
              onClick={() => setSearchOpen(true)}
            >
              <SearchIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <SwipeableDrawer
        anchor='left'
        disableSwipeToOpen={true}
        open={isMenuOpen}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'var(--tg-theme-secondary-bg-color)',
            backgroundImage: 'none',
            width: 'min(100%, 300px)',
          },
        }}
      >
        <List
          sx={{
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '& .MuiListItemIcon-root': {
              minWidth: 30,
              ml: -0.5,
              mr: 1,
            },
          }}
        >
          {/*<ListItem disablePadding>
            <ListItemButton>
              <Avatar /> Profile
            </ListItemButton>
          </ListItem>
          <Divider />*/}
          <ListItem disablePadding>
            <ListItemButton onClick={() => launchExternalControlledApp('telegram', '/a')}>
              <ListItemIcon>
                <TelegramIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Telegram" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => launchExternalControlledApp('youtube')}>
              <ListItemIcon>
                <YouTubeIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="YouTube" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => launchExternalControlledApp('parsec')}>
              <ListItemIcon>
                <FilterNoneIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Parsec" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => launchExternalControlledApp('guacamole')}>
              <ListItemIcon>
                <ComputerIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Guacamole" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={openAccountsVault}>
              <ListItemIcon>
                <AdminPanelSettingsIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Хранилище аккаунтов" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={createGroup}>
              <ListItemIcon>
                <GroupRoundedIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Создать группу" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ContactSupportIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Задать вопрос" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <SettingsIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Настройки" />
            </ListItemButton>
          </ListItem>
          {window.App.installable && (
            <ListItem disablePadding disabled={!window.App.deferredInstallPrompt}>
              <ListItemButton onClick={installPWA}>
                <ListItemIcon>
                  <InstallMobileIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText primary="Добавить на главный экран" />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton onClick={toggleNotifications}>
              <ListItemIcon>
                <NotificationsIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Уведомления" />
              <Switch checked={user.notify} />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton onClick={hardLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Выйти" />
            </ListItemButton>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor='right'
        disableSwipeToOpen={true}
        open={isSearchOpen}
        onOpen={() => setSearchOpen(true)}
        onClose={() => setSearchOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'var(--tg-theme-secondary-bg-color)',
            backgroundImage: 'none',
            width: '100%',
          },
        }}
      >
        <ChannelsSearch />
      </SwipeableDrawer>
      <Chats items={chats} />
      <Fab
        aria-label="add"
        onClick={handleConnect}
        sx={{
          backgroundColor: 'var(--tg-theme-button-color) !important',
          color: 'var(--tg-theme-button-text-color)',
          bottom: '20px',
          position: 'fixed',
          right: '20px',
        }}
      >
        <PersonAddRoundedIcon />
      </Fab>
    </>
  )
}

export default ChatsPage;
