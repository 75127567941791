import cookie from 'cookiejs';

export const wipeOutTraces = () => {
  const shouldRedirect = !!sessionStorage.getItem('redirect');

  indexedDB.databases().then(dbs => {
    dbs.forEach(db => { indexedDB.deleteDatabase(db.name) });
  });
  console.clear();
  localStorage.clear();
  sessionStorage.clear();
  cookie.clear();

  if (shouldRedirect) sessionStorage.setItem('redirect', 1);
};
