import React, { Component } from 'react'

const defaultStyle = {
  width: 'calc(100vw - 72px)',
  maxWidth: '474px',
  frameBorder: '0',
  scrolling: 'no',
  border: 'none',
  overflow: 'hidden',
  margin: '0px 0px -157px 0px',
}

const defaultCaptionStyle = {
  width: 'calc(100vw - 72px)',
  maxWidth: '474px',
  frameBorder: '0',
  scrolling: 'no',
  border: 'none',
  overflow: 'hidden',
  margin: 'calc(-192px - min(100vw - 72px, 474px)) 0px -76px',
}

class InstagramPost extends Component {
  constructor(props) {
    super(props)

    this.state = {
      url: this.props.url,
      id: '',
      height: '250px',
      captionHeight: '450px',
    }
    this.messageHandler = this.messageHandler.bind(this);
    this.captionMessageHandler = this.captionMessageHandler.bind(this);
    this.urlObj = document.createElement('a')

  }

  componentDidMount() {
    window.addEventListener('message', this.messageHandler)
    window.addEventListener('message', this.captionMessageHandler)

    this.iFrame.addEventListener('load', () => {
      this.checkFrame(this.state.id, this.state.idCaption)
    })
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageHandler)
    window.removeEventListener('message', this.captionMessageHandler)
  }

  messageHandler({ data, source }) {
    if (!data || typeof data !== 'string' || source !== this.iFrame.contentWindow) {
      return
    }

    const action = JSON.parse(data)

    if (action.type === 'MEASURE' && action.details?.height) {
      this.setState({
        height: action.details.height + 'px',
      })
    }
  }

  captionMessageHandler({ data, source }) {
    if (!data || typeof data !== 'string' || source !== this.captionIFrame.contentWindow) {
      return
    }

    const action = JSON.parse(data)

    if (action.type === 'MEASURE' && action.details?.height) {
      this.setState({
        captionHeight: action.details.height + 'px',
      })
    }
  }

  checkFrame(id, idCaption) {
    this.iFrame.contentWindow.postMessage(JSON.stringify({ event: 'visible', frame: id }), '*')
    this.captionIFrame.contentWindow.postMessage(JSON.stringify({ event: 'visible', frame: idCaption }), '*')
  }

  componentWillReceiveProps({ url }) {
    if (this.state.url !== url) {
      this.urlObj.href = url
      const id = `instagram-post${this.urlObj.pathname.replace(/[^a-z0-9_]/ig, '-')}`
      const idCaption = `instagram-post-caption${this.urlObj.pathname.replace(/[^a-z0-9_]/ig, '-')}`

      this.setState({ url, id, idCaption }, () =>
        this.checkFrame(id, idCaption)
      )
    }
  }

  render () {
    const { captionHeight, url, height } = this.state
    const { container, style = {} } = this.props
    const { postId } = (url.match(/https:\/\/www\.instagram\.com\/p\/(?<postId>\w+)/)?.groups || {});

    return (
      <div
        data-sharing-id={container}
        style={{
          overflow: 'hidden',
          margin: '-10px -10px 0px -10px',
        }}
      >
        <iframe
          loading="lazy"
          ref={node => this.iFrame = node}
          height={height}
          id={'instagram-post' + this.urlObj.pathname.replace(/[^a-z0-9_]/ig, '-')}
          src={`https://www.instagram.com/p/${postId}/embed`}
          title="Instagram post"
          frameborder="0"
          style={{ ...defaultStyle, ...style }}
        />
        <div style={{ overflow: 'hidden' }}>
          <iframe
            loading="lazy"
            ref={node => this.captionIFrame = node}
            height={captionHeight}
            id={'instagram-post-caption' + this.urlObj.pathname.replace(/[^a-z0-9_]/ig, '-')}
            src={`https://www.instagram.com/p/${postId}/embed/captioned`}
            title="Instagram post caption"
            frameborder="0"
            style={{ ...defaultCaptionStyle, ...style }}
          />
        </div>
      </div>
    )
  }
}

export default InstagramPost
