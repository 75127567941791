export async function all() {
  const response = await this.get('/chats');

  return response;
};

export async function get(id) {
  const response = await this.get(`/chats/${id}`);

  return response;
};

export async function getPrecached(id) {
  const response = await this.getPrecached(`/chats/${id}`);

  return response;
};

export async function create({ id, name, photoUrl, type }) {
  const response = await this.post('/chats', {
    chat: { id, name, photo_url: photoUrl, type },
  });

  return response;
}

export async function update({ id, name, photoUrl, type }) {
  const response = await this.patch(`/chats/${id}`, {
    chat: { name, photo_url: photoUrl, type },
  });

  return response;
}
