import React from 'react';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import { Avatar as MuiAvatar, Box } from '@mui/material';
import { Avatar } from 'components';

const ChatLogo = ({ pending_connection, photo_url, size = 50, sx, type }) => {
  return (
    <Box sx={{ ...sx, height: `${size}px`, width: `${size}px` }}>
      {photo_url && (
        <MuiAvatar src={photo_url} sx={{ height: `${size}px`, width: `${size}px` }} />
      )}
      {!photo_url && type === 'personal' && (
        <Avatar size={size} icon={BookmarkRoundedIcon} />
      )}
      {!photo_url && type === 'channel' && (
        <Avatar size={size} icon={CampaignRoundedIcon} />
      )}
      {/*!photo_url && type === 'personal' && (
        <Avatar size={size} icon={PeopleRoundedIcon} />
      )*/}
      {!photo_url && type === 'direct' && (
        pending_connection && (
          <Avatar size={size} pending icon={PersonRoundedIcon} />
        ) || (
          <Avatar size={size} icon={PersonRoundedIcon} />
        )
      )}
      {!photo_url && type === 'group' && (
        pending_connection && (
          <Avatar size={size} pending icon={PeopleRoundedIcon} />
        ) || (
          <Avatar size={size} icon={PeopleRoundedIcon} />
        )
      )}
    </Box>
  );
};

export default ChatLogo;
