import React from 'react'

const defaultStyle = {
  width: 'calc(100vw - 65px)',
  height: 'calc(min(474px, (100vw - 65px)) * 9 / 16)',
  maxWidth: '474px',
  frameBorder: '0',
  scrolling: 'no',
  border: 'none',
  overflow: 'hidden',
}

const defaultShortsStyle = {
  ...defaultStyle,
  height: 'calc(min(474px, (100vw - 65px)) * 16 / 9)',
}

const YoutubePost = ({ style = {}, url }) => {
  const { videoId } = (url.match(/((([\?\&]v=)|(https:\/\/youtu\.be\/))(?<videoId>\w+))/)?.groups || {});
  const { shortsVideoId } = (url.match(/\/shorts\/(?<shortsVideoId>\w+)/)?.groups || {});
  const { start } = (url.match(/[\?\&]t=(?<start>\d+)/)?.groups || {});

  return (
    <div style={{ overflow: 'hidden', margin: '-10px -10px 0px -10px' }}>
      <iframe
        loading="lazy"
        src={`https://www.youtube.com/embed/${videoId || shortsVideoId}${start ? `?start=${start}` : ''}`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        style={{ ...(videoId ? defaultStyle : defaultShortsStyle), ...style }}
      />
    </div>
  );
}

export default YoutubePost;
