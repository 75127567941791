export async function all({ types }) {
  const response = await this.get(`/blobs?types=${types}`);

  return response;
};

export async function update({ id, data, type }) {
  const response = await this.patch(`/blobs/${id}`, {
    blob: { data, type },
  });

  return response;
}

export async function destroy({ id }) {
  const response = await this.delete(`/blobs/${id}`);

  return response;
}
