export const getChatDisplayName = ({ members_count, name, pending_connection, type }) => {
  let chatName = name;
  chatName ||= (type === 'personal' ? 'Избранное' : '');
  if (members_count < 2) {
    if (type === 'direct') {
      chatName ||= 'Новая связь';
    } else if (type === 'group') {
      chatName ||= 'Новая группа';
    }
  }

  return chatName;
};
